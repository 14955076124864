export const clientNameFooter = "GIU BERGAMO";
export const titleMain = "Giu Bergamo";
export const subtitleMain = "Mova a Vida em você! Ser flexível e adaptável é o segredo da vida";

export const aspectRatioLogoAbout = '2'; // width/height
export const aspectRatioLogoMain = '1'; // width/height

export const borderRadiusButtons = '10px';

export const nomeFornecedorCompleto = "GIULIANA BASSANI BERGAMO";
export const nomeFornecedor = "GIU BERGAMO";
export const artigo = "a";
export const artigoCaps = "A";

export const footerSocialData = [
  {
    type: "material-community",
    name: "instagram",
    link: "https://www.instagram.com/giu_bergamo/?hl=pt",
    profile: "@giu_bergamo"
  },
  {
    type: "material-community",
    name: "whatsapp",
    link: "https://api.whatsapp.com/send?phone=5511981226899",
    number: "(11) 98122-6899"
  },
];

export const drawerClosedLinks = [
  {
    title: "Fale Comigo",
    link: "'https://api.whatsapp.com/send?phone=5511981226899';",
  },
];

export const linkAppleStore = "https://apps.apple.com/us/app/giu-bergamo-yoga/id1641595402"
export const linkPlayStore = "https://play.google.com/store/apps/details?id=app.web.giubergamo_mobile.twa"

export const hasOutsideAboutPage = false;
export const drawerOpenedLinks = [];